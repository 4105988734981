<div class="row">
    <div class="col-12">
        <div class="table-responsive">
            <table datatable class="table table-hover">
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Name</th>
                        <th>MAC</th>
                        <th>Hostname/IP</th>
                        <th>Aktionen</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let host of hosts">
                        <td><svg class="bd-placeholder-img flex-shrink-0 me-2 rounded mr-3" width="32" height="32"
                            xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32"
                            preserveAspectRatio="xMidYMid slice" focusable="false">
                            <title>State</title>
                            <rect class="" width="100%" height="100%" style="fill:#eaeaea" />
                          </svg></td>
                        <td>{{host.title}}</td>
                        <td>{{host.mac}}</td>
                        <td>{{host.hostname}}</td>
                        <td>
                            <button class="btn btn-success mr-5" (click)="start(host)">Starten</button>
                            <button class="btn btn-danger" (click)="remove(host)">Entfernen</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<app-add-host></app-add-host>