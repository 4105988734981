import { Component, OnInit } from '@angular/core';
import { HostsService } from '../hosts.service';

@Component({
  selector: 'app-add-host',
  templateUrl: './add-host.component.html',
  styleUrls: ['./add-host.component.css']
})
export class AddHostComponent implements OnInit {

  host: { id, title, mac, hostname } = { id: null, title: "", mac: "", hostname: "" };

  constructor(public hostService: HostsService) { }

  ngOnInit(): void {
  }

  addHost() {
    if (this.host.mac !== '' && this.host.title !== '' && this.host.hostname !== '') {
      this.hostService.addHost(this.host);
      this.host = { id: null, title: "", mac: "", hostname: "" };
    }


  }

}
