import { ThrowStmt } from '@angular/compiler';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HostsService {

  hosts = [
    {
      mac: "2c:d4:44:bc:87:81",
      title: "FuSi Laptop X",
      hostname: "laptop-4223.fritz.box",
      id: "xx-xxx-x"
    },
    {
      mac: "40:8d:5c:27:1d:d8",
      hostname: "coderbox.local",
      title: "coderbox",
      id: "xx-xxx-y"
    },
    {
      mac: "00:24:1d:5c:3b:8c",
      hostname: "labbox.local",
      title: "Lab PC",
      id: "xx-xxx-s"
    }
  ];

  constructor() { }

  getHosts() {
    return this.hosts;
  }

  addHost(host){
    this.hosts.push(host);
  }

  removeHost(host){
    console.log(host);
    this.hosts = this.hosts.filter(host_item => host_item.id !== host.id);
  }
}
