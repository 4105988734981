import { Component } from '@angular/core';
import {HostListComponent} from './host-list/host-list.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'wakemeup-ui';
}
