<div class="row">
    <div class="col-lg-3 col-sm-12 pb-2">
      <input [(ngModel)]="host.mac" type="text" #mac="ngModel" required class="form-control input-sm" id="mac" placeholder="00:00:00:00:00:00" />
      <div *ngIf="mac.invalid && (mac.dirty || mac.touched)">darf nicht leer sein</div>
    </div>
    
    <div class="col-lg-3 col-sm-12 pb-2">
      <input [(ngModel)]="host.title" type="text" #title="ngModel" required class="form-control input-sm" id="title" placeholder="Mein PC" />
      <div *ngIf="title.invalid && (title.dirty || title.touched)">darf nicht leer sein</div>
    </div>
    <div class="col-lg-3 col-sm-12 pb-2">
      <input [(ngModel)]="host.hostname" required #hostname="ngModel" type="text" class="form-control input-sm" id="hostname" placeholder="mybox.local oder 192.168.0.1" />
      <div *ngIf="hostname.invalid && (hostname.dirty || hostname.touched)">darf nicht leer sein</div>
    </div>
    <div class="col-lg-3 col-sm-12">
      <a href="javascript:void(0);" (click)="addHost()" class="pt-2 btn btn-block btn-secondary" id="btn-add-host">PC hinzufügen</a>
    </div>
  </div>