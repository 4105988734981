import { Component, OnInit } from '@angular/core';
import { HostsService } from '../hosts.service';

@Component({
  selector: 'app-host-list',
  templateUrl: './host-list.component.html',
  styleUrls: ['./host-list.component.css']
})
export class HostListComponent implements OnInit {

  hosts = [];

  constructor(public hostService: HostsService) { }

  ngOnInit(): void {
    this.hosts = this.hostService.getHosts();
  }

  start(): void{

  }

  remove(host): void{
    this.hostService.removeHost(host);
    this.hosts = this.hostService.getHosts();
  }

}
