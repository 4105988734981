import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HostListComponent } from './host-list/host-list.component';
import { HeaderComponent } from './header/header.component';
import { AddHostComponent } from './add-host/add-host.component';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    HostListComponent,
    HeaderComponent,
    AddHostComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DataTablesModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
